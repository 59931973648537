import $ from 'jquery'
import moment from 'moment'
import 'moment-timezone'

import { $elementExists, elementExists } from './shared'
import { selectors } from './selectors'

/**
 * Default interval for updating the clock and countdown timers.
 */
const DEFAULT_INTERVAL_UPDATE = 1000 // ms

/**
 * Number of minutes to add when displaying the time plus 10 minutes.
 */
const MINUTES_TO_ADD = 10 // mins

// -------------- Homepage --------------

/**
 * Initializes the frontend timer that updates the clock and calls the necessary functions.
 * This function is exported and can be used in other modules.
 *
 * @param {number} [interval=DEFAULT_INTERVAL_UPDATE] - The interval at which the timer updates.
 * If no value is provided, the default interval is used.
 */
export const initFrontendTimer = (interval = DEFAULT_INTERVAL_UPDATE) => {
    /**
     * A helper function that updates the frontend clock and sets the real time.
     */
    const updateAll = () => {
        updateFrontEnd()
        setRealTime()
        setRealTimePlus10()
    }

    // Call the helper function once initially
    updateAll()
    // Then set an interval to call the helper function repeatedly
    setInterval(updateAll, interval)
}

/**
 * Updates the frontend clock.
 * This function selects the HTML element with the class 'clock' and updates its content
 * with the current time plus 10 minutes, formatted as 'HH:mm:ss'.
 * If the selected element does not exist, the function does nothing.
 * The hours are set to our working hours if they overflow.
 */
const updateFrontEnd = () => {
    // Select the clock element
    const $clock = $(selectors.content.clock)

    // If the clock element does not exist, exit the function
    if (!$elementExists($clock)) {
        return
    }

    // Select the hours, minutes, and seconds spans within the clock element
    let $hoursSpan = $clock.find(selectors.content.hoursSpan)
    let $minutesSpan = $clock.find(selectors.content.minutesSpan)
    let $secondsSpan = $clock.find(selectors.content.secondsSpan)

    // Get the current time plus 10 minutes, formatted as 'HH:mm:ss'
    let time = moment().add(MINUTES_TO_ADD, 'minutes').format('HH:mm:ss')
    let [h, m, s] = time.split(':')

    // Set hours on our working hours if overflow
    if (parseInt(h) >= 19 || parseInt(h) < 7) {
        h = '08'
        m = '15'
        s = '00'
        const today = new Date()
        const dayIndex = today.getDay()

        // Adjust hours based on the day of the week
        if (
            (dayIndex === 0 && parseInt(h) < 10) || // Sunday
            (dayIndex === 6 && (parseInt(h) >= 16 || parseInt(h) < 10)) || // Saturday
            (dayIndex === 5 && parseInt(h) >= 19) // Friday evening
        ) {
            h = '10'
        }
    }

    // Update the hours, minutes, and seconds spans with the new time
    updateField($hoursSpan, h)
    updateField($minutesSpan, m)
    updateField($secondsSpan, s)
}

// -------------- Registration --------------

/**
 * Initializes a countdown timer for the registration process.
 *
 * @param {number} [interval=DEFAULT_INTERVAL_UPDATE] - The interval at which the timer updates.
 */
export const initRegistrationDoneTimer = (interval = DEFAULT_INTERVAL_UPDATE) => {
    // Check if the countdown timer element exists
    if (!elementExists('#count-down-timer')) {
        return
    }

    // Set the initial time to call
    let timeToCall = 60

    // Start the countdown timer
    const counter = setInterval(() => {
        // Calculate the minutes and seconds
        let minutes = Math.floor(timeToCall / 60)
        let seconds = timeToCall - minutes * 60

        // Decrease the time to call
        timeToCall--

        // If the time to call is less than 0, clear the interval
        timeToCall < 0 && clearInterval(counter)

        // Update the minutes and seconds in the countdown timer
        $(selectors.content.countDownMinutes).text(minutes.toString().length === 2 ? minutes : '0' + minutes)
        $(selectors.content.countDownSeconds).text(seconds.toString().length === 2 ? seconds : '0' + seconds)
    }, interval)
}

// -------------- Client --------------

/**
 * Initializes a dashboard timer that updates the dashboard and checks the top-up availability time.
 *
 * @param {number} [interval=DEFAULT_INTERVAL_UPDATE] - The interval at which the timer updates.
 */
export const initDashBoardTimer = (interval = DEFAULT_INTERVAL_UPDATE) => {
    /**
     * A helper function that updates the dashboard and checks the top-up availability time.
     */
    const updateAll = () => {
        updateDashBoard()
        updateTopUpAvailabilityTime()
    }

    // Call the helper function once initially
    updateAll()
    // Then set an interval to call the helper function repeatedly
    setInterval(updateAll, interval)
}

/**
 * Updates the dashboard clock with the current time and the time when the payment should be made.
 * If the payment is overdue, the seconds are highlighted in red.
 * If the number of days is greater than 99, a class is added to extend the default field size.
 * The function also updates the days, hours, minutes, and seconds fields with the new time.
 */
const updateDashBoard = () => {
    // Select the dashboard clock element
    const $clock = $(selectors.content.dashboardClock)

    // If the dashboard clock element does not exist, exit the function
    if (!$elementExists($clock)) {
        return
    }

    // Select the days, hours, minutes, and seconds spans within the dashboard clock element
    let $dashDays = $clock.find(selectors.content.daysSpan)
    let $dashHours = $clock.find(selectors.content.hoursSpan)
    let $dashMinutes = $clock.find(selectors.content.minutesSpan)
    let $dashSeconds = $clock.find(selectors.content.secondsSpan)

    // Calculate the time when the payment should be made
    // eslint-disable-next-line no-undef
    let shouldBePaid = moment(timeShouldBePaid).endOf('day')
    let now = moment.tz(new Date(), 'Europe/Prague')
    let timesDiff = moment(shouldBePaid.diff(now)).utcOffset(0)

    // If the payment is overdue, highlight the seconds in red
    if (timesDiff._i < 0) {
        !$dashSeconds.hasClass('red') && $dashSeconds.parent().addClass('red')
        timesDiff = moment(now.diff(shouldBePaid)).utcOffset(0) // Magic
    }

    // Format the time difference as 'HH:mm:ss'
    timesDiff = timesDiff.format('HH:mm:ss')

    // Split the time difference into hours, minutes, and seconds
    let [h, m, s] = timesDiff.split(':')
    // Calculate the absolute difference in days between the time when the payment should be made and the current time
    let d = Math.abs(shouldBePaid.diff(now, 'days'))

    // If the number of days is greater than 99, add a class to extend the default field size
    if (d > 99) {
        !$clock.hasClass('over-99') && $clock.addClass('over-99')
    }

    d = d.toString()

    // Update the days, hours, minutes, and seconds fields with the new time
    updateField($dashDays, d)
    updateField($dashHours, h)
    updateField($dashMinutes, m)
    updateField($dashSeconds, s)
}

/**
 * Updates the top-up availability time on the dashboard.
 * This function selects the dashboard clock and top-up block elements and updates their content.
 * If the selected elements do not exist, the function does nothing.
 */
const updateTopUpAvailabilityTime = () => {
    // Select the dashboard clock element
    const $clock = $(selectors.content.dashboardClock)

    // If the dashboard clock element does not exist, exit the function
    if (!$elementExists($clock)) {
        return
    }

    // Load values from clock
    let dashDays = parseInt($clock.find(selectors.content.daysSpan).html())
    let dashHours = parseInt($clock.find(selectors.content.hoursSpan).html())

    // Select the top-up block element
    const $topUp = $(selectors.content.topUpBlock)

    // If the top-up block element does not exist, exit the function
    if (!$elementExists($topUp)) {
        return
    }

    // Fetch TopUp availability time
    const $dashDaysToTopUp = $topUp.find(selectors.content.daysToTopUp)
    const $dashHoursToTopUp = $topUp.find(selectors.content.hoursToTopUp)

    dashDays = (dashDays - 5).toString()
    dashHours = dashHours.toString()

    // Update the days and hours fields with the new time
    updateField($dashDaysToTopUp, dashDays)
    updateField($dashHoursToTopUp, dashHours)
}

// -------------- Helpers --------------

/**
 * Sets the real time on the dashboard.
 * This function selects the HTML element with the class 'actualTime' and updates its content
 * with the current time, formatted as 'H:mm'.
 * If the selected element does not exist, the function does nothing.
 */
const setRealTime = () => {
    const $element = $(selectors.content.actualTime)
    $elementExists($element) && $element.html(moment().format('H:mm'))
}

/**
 * Sets the real time plus 10 minutes on the dashboard.
 * This function selects the HTML element with the class 'actualTimePlus1' and updates its content
 * with the current time plus 10 minutes, formatted as 'H:mm'.
 * If the selected element does not exist, the function does nothing.
 */
const setRealTimePlus10 = () => {
    const $element = $(selectors.content.actualTimePlus1)
    $elementExists($element) && $element.html(moment().add(MINUTES_TO_ADD, 'minutes').format('H:mm'))
}

/**
 * Updates the HTML content of a field if the new value is different from the old value.
 * This function is used to prevent unnecessary DOM updates.
 *
 * @param {Object} $field - The jQuery object representing the HTML element to update.
 * @param {string} value - The new value to set.
 */
const updateField = ($field, value) => {
    const oldValue = $field.html()

    if (oldValue !== value) {
        $field.html('') // Clear the field before updating
        $field.html(value)
    }
}
