import $ from 'jquery'
import _ from 'lodash'
import { selectors } from '../selectors'
import { ATTR } from './helpers'
import { elementExists } from '../shared'

const MESSAGES = {
    incomeI: 'Vaše příjmy musí být nižší nebo stejné jako příjmy domácnosti.',
    incomeII: 'Příjmy domácnosti musí být vyšší nebo stejné jako Vaše příjmy.',
    incomeIII: 'Pokud je počet členů v domácnosti s příjmem 1 - příjmy domácnosti se musí rovnat Vašim příjmům.',
    costsI: 'Vaše výdaje musí být nižší nebo stejné jako výdaje domácnosti.',
    costsII: 'Výdaje domácnosti musí být vyšší nebo stejné jako Vaše výdaje.',
    costsIII: 'Pokud je počet členů v domácnosti 1 - výdaje domácnosti se musí rovnat Vašim výdajům.',
    personsI: 'Počet členů domácnosti nemůže být nižší než počet počet členů domácnosti s příjmem.',
    personsII: 'Počet členů domácnosti s příjmem nemůže být vyšší než počet členů domácnosti.',
}

const INPUTS_REGI = {
    netIncome: selectors.inputs.netIncome,
    householdNetIncome: selectors.inputs.householdNetIncome,
    costs: selectors.inputs.costs,
    householdCosts: selectors.inputs.householdCosts,
    householdPersons: selectors.inputs.householdPersons,
    householdPersonsWithIncome: selectors.inputs.householdPersonsWithIncome,
}

const INPUTS_REGI_COMPLETE = {
    netIncome: selectors.inputs.netIncomeComplete,
    householdNetIncome: selectors.inputs.householdNetIncomeComplete,
    costs: selectors.inputs.costsComplete,
    householdCosts: selectors.inputs.householdCostsComplete,
    householdPersons: selectors.inputs.householdPersonsComplete,
    householdPersonsWithIncome: selectors.inputs.householdPersonsWithIncomeComplete,
}

const OPERATORS = {
    EQ: '===',
    NEQ: '!==',
    LT: '<',
    LTE: '<=',
    GT: '>',
    GTE: '>=',
}

/**
 * resolve(null) -> skip
 * resolve(true) -> success
 * resolve(message) -> success with custom feedback
 * reject() -> failure
 * reject(message) -> failure with custom feedback
 */
export const household = ({ selector }, { resolve }) => {
    const [inputIncome, inputCosts, inputHouseIncome, inputHouseCosts, inputHousePersons, inputHousePersonsIncome] = inputs()
    const [$inputIncome, $inputCosts, $inputHouseIncome, $inputHouseCosts, $inputHousePersons, $inputHousePersonsIncome] = [$(inputIncome), $(inputCosts), $(inputHouseIncome), $(inputHouseCosts), $(inputHousePersons), $(inputHousePersonsIncome)]

    if ([inputIncome, inputHouseIncome].includes(selector) && !empty([$inputIncome, $inputHouseIncome])) {
        reset([$inputIncome, $inputHouseIncome])

        if (!validateIncomeOneP($inputIncome, $inputHouseIncome, $inputHousePersonsIncome)) {
            return
        }
        if (!validateIncome($inputIncome, $inputHouseIncome)) {
            return
        }
    }

    if ([inputCosts, inputHouseCosts].includes(selector) && !empty([$inputCosts, $inputHouseCosts])) {
        reset([$inputCosts, $inputHouseCosts])

        if (!validateCostsOneP($inputCosts, $inputHouseCosts, $inputHousePersons)) {
            return
        }
        if (!validateCosts($inputCosts, $inputHouseCosts)) {
            return
        }
    }

    if ([inputHousePersons, inputHousePersonsIncome].includes(selector)) {
        !empty([$inputHousePersons]) && reset([$inputHousePersons])
        !empty([$inputHousePersonsIncome]) && reset([$inputHousePersonsIncome])

        if (!empty([$inputHousePersons, $inputHousePersonsIncome])) {
            if (!validatePersons($inputHousePersons, $inputHousePersonsIncome)) {
                return
            }
        }

        if (!empty([$inputIncome, $inputHouseIncome])) {
            reset([$inputIncome, $inputHouseIncome])

            if (!validateIncomeOneP($inputIncome, $inputHouseIncome, $inputHousePersonsIncome)) {
                return
            }
            if (!validateIncome($inputIncome, $inputHouseIncome)) {
                // return // -> To not skip validateCosts()
            }
        }

        if (!empty([$inputCosts, $inputHouseCosts])) {
            reset([$inputCosts, $inputHouseCosts])

            if (!validateCostsOneP($inputCosts, $inputHouseCosts, $inputHousePersons)) {
                return
            }
            if (!validateCosts($inputCosts, $inputHouseCosts)) {
                // return // -> To not skip validateIncome()
            }
        }
    }

    resolve(true)
}

// ---------------------- IncomeValidator ----------------------

const validateIncomeOneP = ($inputIncome, $inputHouseIncome, $inputHousePersonsIncome) => {
    if ($inputHousePersonsIncome.val() === '1' && !compare($inputIncome, OPERATORS.EQ, $inputHouseIncome)) {
        $inputIncome._forceError(ATTR.VALID._CUSTOM, MESSAGES.incomeIII)
        $inputHouseIncome._forceError(ATTR.VALID._CUSTOM, MESSAGES.incomeIII)

        return false
    }

    return true
}

const validateIncome = ($inputIncome, $inputHouseIncome) => {
    if (!compare($inputIncome, OPERATORS.LTE, $inputHouseIncome)) {
        $inputIncome._forceError(ATTR.VALID._CUSTOM, MESSAGES.incomeI)
        $inputHouseIncome._forceError(ATTR.VALID._CUSTOM, MESSAGES.incomeII)

        return false
    }

    return true
}

// ---------------------- CostsValidator ----------------------

const validateCosts = ($inputCosts, $inputHouseCosts) => {
    if (!compare($inputCosts, OPERATORS.LTE, $inputHouseCosts)) {
        $inputCosts._forceError(ATTR.VALID._CUSTOM, MESSAGES.costsI)
        $inputHouseCosts._forceError(ATTR.VALID._CUSTOM, MESSAGES.costsII)

        return false
    }

    return true
}

const validateCostsOneP = ($inputCosts, $inputHouseCosts, $inputHousePersons) => {
    if ($inputHousePersons.val() === '1' && !compare($inputCosts, OPERATORS.EQ, $inputHouseCosts)) {
        $inputCosts._forceError(ATTR.VALID._CUSTOM, MESSAGES.costsIII)
        $inputHouseCosts._forceError(ATTR.VALID._CUSTOM, MESSAGES.costsIII)

        return false
    }

    return true
}

// ---------------------- PersonsValidator ----------------------

const validatePersons = ($inputHousePersons, $inputHousePersonsIncome) => {
    if (!compare($inputHousePersons, OPERATORS.GTE, $inputHousePersonsIncome)) {
        $inputHousePersons._forceError(ATTR.VALID._CUSTOM, MESSAGES.personsI)
        $inputHousePersonsIncome._forceError(ATTR.VALID._CUSTOM, MESSAGES.personsII)

        return false
    }

    return true
}

// ---------------------- Helpers ----------------------

const inputs = () => {
    return [
        elementExists(INPUTS_REGI.netIncome) ? INPUTS_REGI.netIncome : INPUTS_REGI_COMPLETE.netIncome,
        elementExists(INPUTS_REGI.costs) ? INPUTS_REGI.costs : INPUTS_REGI_COMPLETE.costs,
        elementExists(INPUTS_REGI.householdNetIncome) ? INPUTS_REGI.householdNetIncome : INPUTS_REGI_COMPLETE.householdNetIncome,
        elementExists(INPUTS_REGI.householdCosts) ? INPUTS_REGI.householdCosts : INPUTS_REGI_COMPLETE.householdCosts,
        elementExists(INPUTS_REGI.householdPersons) ? INPUTS_REGI.householdPersons : INPUTS_REGI_COMPLETE.householdPersons,
        elementExists(INPUTS_REGI.householdPersonsWithIncome) ? INPUTS_REGI.householdPersonsWithIncome : INPUTS_REGI_COMPLETE.householdPersonsWithIncome,
    ]
}

const reset = ($inputs) => {
    _.map($inputs, ($input) => $input._forceSuccess(ATTR.VALID._CUSTOM, true))
}

const compare = ($inputI, operator, $inputII) => {
    return Function('return ' + `${parseInt($inputI.val())} ${operator} ${parseInt($inputII.val())}`)()
}

const empty = ($inputs) => {
    return _.reduce($inputs, (result, $input) => result || $input._isEmpty(), false)
}
