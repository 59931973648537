import moment from 'moment'

export const config = {
    // Sliders
    feeFormula: 'Y<=6?0.1*X:(Y<=13?0.15*X:(Y<=20?0.25*X:0.325*X))',
    feeFormulaMorePayments: '0.6*X',

    // CompanyInfo
    companyPhoneNumber: '+420 226 219 946',
    companyPhoneNumberHref: 'tel:+420226219946',
    workHoursFrom: moment(process.env.WORK_HOURS_FROM, 'HH:mm:ss'),
    workHoursTo: moment(process.env.WORK_HOURS_TO, 'HH:mm:ss'),
    weekendWorkHoursFrom: moment(process.env.WEEKEND_WORK_HOURS_FROM, 'HH:mm:ss'),
    weekendWorkHoursTo: moment(process.env.WEEKEND_WORK_HOURS_TO, 'HH:mm:ss'),
}

export const mode = {
    PRODUCTION: 'prod',
    TEST: 'test',
    DEVELOPMENT: 'dev',
}

export const settings = {
    mode: () => process.env.APP_ENV,
    assets: () => process.env.ASSET_CDN_URL,
}

export const translations = {
    currencyLang: 'Kč',
    daysLang: 'dní',
    offerLang: 'Kč nabízíme ',
    loanLang1: 'od 2. půjčky',
    loanLang2: 'od 3. půjčky',
    loanLang3: 'od 4. půjčky',
    loanLang4: 'od 5. půjčky',
    ratings: [
        {
            name: 'Martina Riesová',
            age: 61,
            text: 'Je to poprvé, co jsem nemusela podepisovat štosy papírů. Jsem v invalidním důchodu, takže jsem ráda, že jsem mohla vše vyřídit z pohodlí domova.',
        },
        {
            name: 'Jiří Ullmann',
            age: 22,
            text: 'Byl jsem překvapen rychlostí, s jakou mi přišly peníze, a jednoduchostí uživatelského rozhraní. Děkuji COOL CREDITu!',
        },
        {
            name: 'Petr Salivar',
            age: 39,
            text: 'Nedávno jsem přišel o práci. Teď už mám sice novou, ale nebýt COOL CREDITu, tak bych nikdy nebyl schopný zaplatit hypotéku a uživit rodinu.',
        },
        {
            name: 'Aleš Kopecký',
            age: 25,
            text: 'Před výplatou už jsem neměl na to jet s kamarády na hory, ale Půjčka do výplaty mi pomohla a já s partou mohl odjet. Děkuji!',
        },
        {
            name: 'Zuzana Skálová',
            age: 28,
            text: 'COOL CREDIT mi pomohl překlenout dočasné finanční obtíže. Mohu jedině doporučit!',
        },
        {
            name: 'Anna Burešová',
            age: 26,
            text: 'Mám ráda, když můžu věci dělat z pohodlí svého gauče. Nikdy jsem si nemyslela, že to půjde i v případě půjčování peněz. Tedy alespoň dokud jsem neobjevila COOL CREDIT.',
        },
    ],
    warningOnPageLeavingLang: 'Opravdu chcete odejít? Stačí dokončit žádost a peníze můžete mít na Vašem účtu již za několik minut!',
    finnishRegistrationTitle: 'Dokončete registraci!',
    flashMessageActualizeBankAccountFail: 'Něco se nepodařilo, zkuste to prosím znovu nebo nás kontaktujte.',
    updatePasswordFailed: 'Heslo se nepodařilo uložit.',
    passwordMissing: 'Zadejte heslo.',
    updatePasswordSucceed: 'Heslo úspěšně uloženo.',
}
