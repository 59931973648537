import $ from 'jquery'
import _ from 'lodash'

import { selectors } from '../selectors'

export const storePreRegistrationData = () => {
    const $inputs = [$(selectors.inputs.name), $(selectors.inputs.surname), $(selectors.inputs.phoneNumber), $(selectors.inputs.email), $(selectors.inputs.nin)]

    const firstStepValid = _.reduce($inputs, (result, $input) => result && $input._isValid(), true)
    if (!firstStepValid) {
        return
    }

    const data = $(selectors.forms.registrationForm).serializeArray()
    const normalized = transformData(data)

    fetch(preregistrationUrl, {
        method: 'POST',
        body: JSON.stringify(normalized),
    }).catch((e) => console.warn(e))
}

// 0: {name: 'registration[name]', value: 'Adam'}, 1: {name: 'registration[surname]', value: 'Novák'}
// ->
// {..., [name]: 'Adam', [surname]: 'Novák',...}
const transformData = (data) => {
    const normalized = {}

    _.forIn(data, ({ name, value }) => {
        const key = name.replace(/registration/g, '').replace(/[\][]/g, '') // removes "registration[...]"
        _.extend(normalized, {
            [key]: value.trim(),
        })
    })

    return normalized
}
