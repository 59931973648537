import $ from 'jquery'
import { selectors } from '../selectors'
import { $elementExists } from '../shared'

const SIDE_NAV_WIDTH = 280
const $sideNav = $(selectors.content.sideNavOld)
const $menuBtn = $(selectors.buttons.menuBtn)
const $closeMenuBtn = $(selectors.buttons.closeMenuBtn)

// -------------------- Menu --------------------

export function initMenu() {
    // Init toggle
    $elementExists($menuBtn) && $menuBtn.click(() => toggleNav())

    // Init outside click
    $('document').on('click', 'body *', () => hideNav())

    // Init x button click
    $elementExists($closeMenuBtn) && $closeMenuBtn.click(() => toggleNav())
}

const hideNav = () => $sideNav.width(0)

const showNav = () => $sideNav.width(SIDE_NAV_WIDTH)

const toggleNav = () => {
    $sideNav.width() === SIDE_NAV_WIDTH && hideNav()
    $sideNav.width() === 0 && showNav()
}

// -------------------- Menu Button --------------------

export const hideMenuButton = () => $(selectors.buttons.menuBtn).hide()

export const showMenuButton = () => $(selectors.buttons.menuBtn).show()

export const toggleMenuButton = (hide = true) => {
    hide && hideMenuButton()
    !hide && showMenuButton()
}
