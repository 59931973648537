// https://robinherbots.github.io/Inputmask/#/documentation

import InputMask from 'inputmask'

export default {
    nin: InputMask({ regex: '^[0-9]{6}/[0-9]{3,4}$' }),
    phoneNumber: InputMask({ regex: '^[679][0-9]{2} [0-9]{3} [0-9]{3}$' }),
    phoneNumberCzech: InputMask({ regex: '^(601|602|603|604|605|606|607|608|700|702|703|704|705|706|719|720|721|722|723|724|725|726|727|728|729|730|731|732|733|734|735|736|737|738|739|760|770|771|772|773|774|775|776|777|778|779|790|791|792|793|794|795|797|799) [0-9]{3} [0-9]{3}$' }),
    phoneNumberSlovak: InputMask({ regex: '^(901|902|903|904|905|906|907|908|909|910|911|912|914|915|916|917|918|919|940|943|944|945|947|948|949|950|951|959|972|976|980|986|988) [0-9]{3} [0-9]{3}$' }),
    idCardNumber: InputMask({ regex: '^([0-9]{9}|[a-zA-Z]{2}[0-9]{6})$' }),
    accountNumber: InputMask({ regex: '^([0-9-]{4,17}$' }),
    selfEmployerIco: InputMask({ regex: '^[0-9]{4,8}$' }),

    income: InputMask('currency', {
        inputType: 'text',
        placeholder: '___',
        groupSeparator: ' ',
        autoUnmask: true, // Automatically unmask the value when retrieved - $(input).val()
        autoGroup: true,
        rightAlign: false,
        digitsOptional: false,
        allowMinus: false,
        digits: 0,
        max: 999999,
    }),

    costs: InputMask('currency', {
        inputType: 'text',
        placeholder: '___',
        groupSeparator: ' ',
        autoUnmask: true, // Automatically unmask the value when retrieved - $(input).val()
        autoGroup: true,
        rightAlign: false,
        digitsOptional: false,
        allowMinus: false,
        digits: 0,
        max: 999999,
    }),

    persons: InputMask({ regex: '^[1-9]{1}$' }),

    zipCode: InputMask({ regex: '^[0-9]{3} [0-9]{2}$' }),
    pinCode: InputMask({ regex: '^[0-9]{4,6}$' }),
}
