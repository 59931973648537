import $ from 'jquery'

import { translations } from '../config'
import { selectors } from '../selectors'
import { $buttonSpinnerStart } from '../validation/helpers'
import { $disableElement } from '../shared'
import { FORM_STEP_ACTIVE, showNextFormStep, showPrevFormStep } from './formSteps'

export const initWindowEvents = () => {
    // TODO FIX FOR /registrace/[zadost|doplneni-udaju]/{HASH} ??
    if (!isRegistrationOPFormPath() && !isRegistrationMPFormPath()) {
        return
    }

    initBrowserNavigation()
    initConfirmPageLeaving()
    initFlashingTitle()
}

// -------- Browser Navigation --------

const initBrowserNavigation = () => {
    // https://developer.mozilla.org/en-US/docs/Web/API/Window/popstate_event

    window.addEventListener('popstate', (e) => {
        if (!e.state?.step) return

        e.state.step > FORM_STEP_ACTIVE && showNextFormStep(false)
        e.state.step < FORM_STEP_ACTIVE && showPrevFormStep(false)
    })
}

// -------- ConfirmPageLeaving --------

let DISPLAY_CONFIRMATION = true

export const unBindConfirmPageLeaving = () => (DISPLAY_CONFIRMATION = false)

const initConfirmPageLeaving = () => {
    // https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event

    window.addEventListener('beforeunload', (e) => {
        if (DISPLAY_CONFIRMATION) {
            e.preventDefault()
            e.returnValue = true
        }
    })
}

// -------- RegistrationForm Duplicity --------

export const initFormResubmitDuplicityPrevention = () => {
    $(selectors.forms.registrationForm).on('submit', () => {
        $buttonSpinnerStart($(selectors.submits.registrationStep4))
        $disableElement($(selectors.submits.registrationStep4))
    })
}

// -------- FlashingTitle --------

const initFlashingTitle = () => {
    const originalTitle = document.title
    let flashInterval

    const reset = () => {
        document.title = originalTitle
        clearInterval(flashInterval)
    }

    const flashTitle = () => {
        if (document.title === originalTitle) {
            document.title = translations.finnishRegistrationTitle
        } else {
            document.title = originalTitle
        }
    }

    window.addEventListener('load', () => {
        document.addEventListener('visibilitychange', () => {
            document.hidden && (flashInterval = setInterval(flashTitle, 1500))
            !document.hidden && reset()
        })
    })
}

// --------- Location ---------

const REGISTRATION_OP_FORM_PATH = '/registrace'

const REGISTRATION_MP_FORM_PATH = '/registrace/pujcka-na-splatky'

const getHref = () => window.location.href // eslint-disable-line

const getHostName = () => window.location.hostname // eslint-disable-line

const getPathName = () => window.location.pathname.replace(/\/app_dev.php/g, '')

const isRegistrationOPFormPath = () => getPathName() === REGISTRATION_OP_FORM_PATH

const isRegistrationMPFormPath = () => getPathName() === REGISTRATION_MP_FORM_PATH

// --------- Scroll ---------

export const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
    })
}
