import $ from 'jquery'

import { hideMenuButton } from '../app/menu'
import { elementExists } from '../shared'
import { selectors } from '../selectors'

export let FORM_STEP_ACTIVE = 1
const FORM_STEPS_TOTAL = 4

const FORM_STEP_ID_PREFIX = '#form-step-'
const FORM_STEP_IDS = '[id^="form-step-"]'

export const initFormSteps = () => {
    if (!elementExists(selectors.forms.registrationForm)) {
        return
    }

    initHistory()
    addListeners()
    hideMenuButton()
}

export const showNextFormStep = (push = true) => {
    if (FORM_STEP_ACTIVE >= FORM_STEPS_TOTAL) {
        return
    }

    FORM_STEP_ACTIVE++
    push && setHistoryStep()
    navigate()
}

export const showPrevFormStep = (push = true) => {
    if (FORM_STEP_ACTIVE <= 1) {
        return
    }

    FORM_STEP_ACTIVE--
    push && setHistoryStep()
    navigate()
}

const navigate = () => {
    hideFormSteps()
    revealFormStep()
    scrollTop()
    sendAnalyticsReport(FORM_STEP_ACTIVE)
}

const hideFormSteps = () => $(selectors.forms.registrationForm).find(FORM_STEP_IDS).hide()

const revealFormStep = () => {
    const step = getHistoryStep()

    $(selectors.forms.registrationForm)
        .find(FORM_STEP_ID_PREFIX + step)
        .show()
}

const scrollTop = () => {
    const ANIMATION_SPEED = 250 // ms

    $('html, body').animate({ scrollTop: 5 }, ANIMATION_SPEED) // 5px not to trigger Header enlarge
}

// eslint-disable-next-line
const sendAnalyticsReport = (step) => {
    ga('send', { hitType: 'pageview', page: 'registrace/krok-' + step, title: 'Krok ' + step })
}

const addListeners = () => {
    $(selectors.forms.regiStepBack).on('click', () => showPrevFormStep())
}

// -------------- Browser History --------------

const initHistory = () => {
    // https://developer.mozilla.org/en-US/docs/Web/API/History/scrollRestoration
    history.scrollRestoration = 'manual'

    // https://developer.mozilla.org/en-US/docs/Web/API/History/pushState
    history.pushState({ step: FORM_STEP_ACTIVE }, '')
}

const setHistoryStep = () => history.pushState({ step: FORM_STEP_ACTIVE }, '')

const getHistoryStep = () => history.state.step ?? FORM_STEP_ACTIVE

// -------------- Debug --------------

window.step2 = () =>
    $(selectors.forms.registrationForm)
        .find(FORM_STEP_ID_PREFIX + '2')
        .show()

window.step3 = () =>
    $(selectors.forms.registrationForm)
        .find(FORM_STEP_ID_PREFIX + '3')
        .show()

window.step4 = () =>
    $(selectors.forms.registrationForm)
        .find(FORM_STEP_ID_PREFIX + '4')
        .show()
