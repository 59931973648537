export const input = {
    submit: 'submit',
    select: 'select',
    radio: 'radio',
    checkbox: 'checkbox',
    date: 'date',
    range: 'range',
    textarea: 'textarea',
    text: 'text',
    file: 'file',
}

export const rule = {
    regex: 'regex', // <Input type=[text|date|password|tel|number|email]> - Subset of [rule.mask]
    mask: 'mask', // <Input type=[text|date|tel|number]> - Superset of [rule.regex], Consist of [rule.regex] + [input formatter]
    selected: 'selected', // <Input type=[checkbox|radio]>, <Select>
    ranged: 'ranged', // <Input type=range>
    external: 'external', // fn()
    custom: 'custom', // fn()
}

export const callback = {
    onSuccess: 'onSuccess', // Replaces default '.submit()' method
    onError: 'onError', // Replaces default '.submit()' method
    onValidate: 'onValidate', // Form <Input>/<Select> validation event
    beforeSubmit: 'beforeSubmit', // Triggered before onSuccess/onError
}

export const flag = {
    detached: 'detached', // <Input> skipped on form validation
}
